<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="12">
        <v-row>
          <v-col md="6" class="my-auto py-0">
            <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
          </v-col>
          <v-col md="6" class="text-right py-0">
            <v-btn
              :disabled="pageLoading || !formValid"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateOrCreate"
            >
              Save
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="8">
        <v-card outlined height="558">
          <v-card-text>
            <v-row>
              <v-col md="12">
                <v-form
                  ref="customerForm"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateOrCreate"
                >
                  <v-row>
                    <v-col md="3" class="my-auto py-0">
                      <label for="first-name" class="btx-label mt-2 required">Name</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <v-layout>
                        <v-flex class="max-width-100px">
                          <SelectInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items.sync="listTitle"
                            id="salutation"
                            v-model="customer.title"
                            placeholder="Title"
                          ></SelectInput>
                        </v-flex>
                        <v-flex class="mx-2">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="first-name"
                            placeholder="First Name"
                            v-model="customer.first_name"
                            :rules="[vrules.required(customer.first_name, 'First Name')]"
                            :class="{ required: !customer.first_name }"
                          ></TextInput>
                        </v-flex>
                        <v-flex>
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="last-name"
                            v-model="customer.last_name"
                            placeholder="Last Name"
                          ></TextInput>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="diplay-name" class="btx-label mt-2 required">Display Name</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <TextInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="diplay-name"
                        v-model="customer.display_name"
                        :rules="[vrules.required(customer.display_name, 'Display Name')]"
                        :class="{ required: !customer.display_name }"
                        placeholder="Display Name"
                      ></TextInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="user-name" class="btx-label mt-2 required">User Name</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <TextInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="user-name"
                        v-model="customer.user_name"
                        :rules="[vrules.required(customer.user_name, 'User Name')]"
                        :class="{ required: !customer.user_name }"
                        placeholder="User Name"
                      ></TextInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="gender" class="btx-label mt-2 required">Email</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <EmailInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="email"
                        v-model="customer.email"
                        :rules="[vrules.required(customer.email, 'Email')]"
                        :class="{ required: !customer.email }"
                        placeholder="Email"
                      ></EmailInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="phone-number" class="btx-label mt-2 required">Phone Number</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <PhoneInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model="customer.phone_number"
                        :rules="[vrules.required(customer.phone_number, 'Phone Number')]"
                        :class="{ required: !customer.phone_number }"
                        id="phone-number"
                        placeholder="Phone Number"
                      ></PhoneInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="role" class="btx-label mt-2 required">Role</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <SelectInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :items.sync="genderList"
                        v-model="customer.gender"
                        :rules="[vrules.required(customer.gender, 'Role')]"
                        :class="{ required: !customer.gender }"
                        id="role"
                        placeholder="Role"
                      ></SelectInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="address-line-2" class="btx-label mt-2 required">Password</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <TextInput
                        type="password"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="address-line-2"
                        v-model="customer.user_password"
                        :rules="[vrules.required(customer.user_password, 'Password')]"
                        :class="{ required: !customer.user_password }"
                        placeholder="Address Line 2"
                      ></TextInput>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" class="">
        <v-card outlined height="558">
          <v-card-text>
            <v-row>
              <v-col
                md="12"
                align="center"
                justify="center"
                class="d-flex align-center justify-center"
              >
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" style="height: 100%; width: 100%">
                      <ImageTemplate></ImageTemplate>
                      <v-btn
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        class="white--text mx-2"
                        depressed
                        color="blue darken-4"
                        tile
                        style
                        v-on:click="fileInput()"
                      >
                        {{ customer.image ? "Reset Image" : "Upload Image" }}
                        <v-icon right style="font-size: 21px">
                          {{ `mdi-${customer.image ? "refresh" : "cloud-upload-outline"}` }}
                        </v-icon>
                      </v-btn>
                      <div class="mt-3">
                        <label for="product-image" class="field-label mt-0 mb-1">
                          Allowed JPG or PNG. Max size of 800K
                        </label>
                      </div>
                    </div>
                  </template>
                  <span> Upload Images </span>
                </v-tooltip>
              </v-col>

              <v-col md="12" class="pa-0" v-show="false">
                <v-row class="pa-0" style="margin: 0% !important">
                  <v-col md="2" sm="3" align="center" justify="center" class="pa-0 mt-2">
                    <v-file-input
                      id="product-image"
                      accept="image/png, image/jpeg, image/bmp"
                      ref="fileInput"
                      class="d-none"
                      v-on:change="handleFilesUpload()"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
// import PostalCode from "@/view/components/PostalCode";
// import PhoneTemplate from "@/view/components/Phone";
import PhoneInput from "@/view/components/PhoneInput";
// import DatePicker from "@/view/components/DatePicker";
import ImageTemplate from "@/view/components/Image";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "customer-create",
  title: "Create Customer",
  data() {
    return {
      pageLoading: false,
      formValid: true,
      customerId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "Manager",
          value: "manager",
        },
        {
          text: "SEO",
          value: "seo",
        },
        {
          text: "Executive",
          value: "executive",
        },
      ],
      customer: {
        title: null,
        first_name: null,
        last_name: null,
        gender: null,
        email: null,
        user_name: null,
        phone_number: null,
        display_name: null,
        user_password: null,
        unit_number: null,
        postal_code: null,
        country: null,
        image: null,
      },
    };
  },
  components: {
    TextInput,
    EmailInput,
    SelectInput,
    // DatePicker,
    // PostalCode,
    // PhoneTemplate,
    ImageTemplate,
    PhoneInput,
  },
  methods: {
    pageTitle() {
      if (this.customerId) {
        return "Update User";
      }
      return "Create User";
    },
    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_CUSTOMER(_this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getCustomer() {
      const customer = await GET_CUSTOMER(this.customerId);
      this.customer = {
        title: customer.title,
        first_name: customer.first_name,
        last_name: customer.last_name,
        email: customer.email,
        user_name: customer.user_name,
        gender: customer.gender,
        phone_number: customer.phone_number,
        display_name: customer.display_name,
        user_password: customer.user_password,
        unit_number: customer.unit_number,
        postal_code: customer.postal_code,
        country: customer.country,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "customer-update") {
      const { id } = params;
      if (id) {
        this.customerId = toSafeInteger(id);
        this.getCustomer();
      } else {
        this.goBack();
      }
    }
  },
};
</script>

<style>
.v-image.v-responsive.white.lighten-2.theme--light {
  height: 272px;
}
</style>
